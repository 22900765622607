var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"id":"dashboard","fluid":"","tag":"section"}},[_c('div',{staticClass:"d-flex justify-space-between dashboardTopBar"},[_c('div',{staticClass:"d-flex ms-auto dashboardFilterBar"},[_c('header-filters',{attrs:{"class-name":"dashboard","filter-names":['make', 'model'],"is-date-show":"","date-selected":false,"date-validation":false},on:{"onChange":_vm.onFiltersChange}}),_c('div',{staticClass:"marleftCustom"},[_c('card-options',{attrs:{"options":_vm.options,"tenant-tech":_vm.vehicle_tech},on:{"change":_vm.callSectionData}})],1)],1)]),_c('section',{staticClass:"sections-wrapper"},[(
        _vm.dashboadSortData.section_1 && _vm.dashboadSortData.section_1.length > 0
      )?_c('section-one',{attrs:{"loading":_vm.sectionOneLoader,"options":_vm.options,"vehicle-tech":_vm.vehicle_tech}}):_vm._e(),_c('div',{staticClass:"row marZero"},[(
          _vm.dashboadSortData.section_2 && _vm.dashboadSortData.section_2.length > 0
        )?_c('section-two',{attrs:{"loading":_vm.sectionTwoLoader,"options":_vm.options,"vehicle-tech":_vm.vehicle_tech}}):_vm._e(),(
          _vm.dashboadSortData.section_3 && _vm.dashboadSortData.section_3.length > 0
        )?_c('section-three',{attrs:{"loading":_vm.sectionThreeLoader,"options":_vm.options,"vehicle-tech":_vm.vehicle_tech}}):_vm._e()],1),(
        _vm.dashboadSortData.section_4 && _vm.dashboadSortData.section_4.length > 0
      )?_c('section-four',{attrs:{"loading":_vm.sectionFourLoader,"options":_vm.options,"vehicle-tech":_vm.vehicle_tech}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }